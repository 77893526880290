<template>
	<div>
		<Loading :show="loading" label="3, 2, 1..." />
		<v-col offset-md="3" xl="6" md="6" sm="12" cols="12" v-if="sessionScore && formationProgress">
			<SessionGoToReportBlock
				v-if="sessionScore.score >= 80"
				:totalScoreInPercent="sessionScore.score"
				:formationProgress="formationProgress"
				:session="session"
			/>
			<ScoreFinal v-else :totalScoreInPercent="sessionScore.score" />

			<FeedbackSession :user="formationProgress.user" />
			<ButtonsFinalSessionResult class="mt-8" :sessionScore="sessionScore" :formationProgress="formationProgress" />
			<StreakInExerciseResult class="mt-8" />
			<SessionPerformance
				class="mt-8"
				:totalScoreInPercent="sessionScore.score"
				title="Performance du jour"
				subTitlePassed="Ton taux de bonnes réponses est au-dessus de la moyenne, continue comme ça !"
				subTitleNotPassed="Ton taux de bonnes réponses est en-dessous de la moyenne, persévère !"
				:displayPoint="true"
			/>
			<SessionBlocksPerformance v-if="session" class="mt-8" :session="session" :sessionScore="sessionScore" />
		</v-col>
	</div>
</template>

<script>
import Loading from 'vue-full-loading';
import ScoreFinal from '@/components/exercice/results/default/ScoreFinal.vue';
import SessionPerformance from '@/components/session-result/SessionPerformance.vue';
import SessionBlocksPerformance from '@/components/session-result/SessionBlocksPerformance.vue';
import ButtonsFinalSessionResult from '@/components/session-result/ButtonsFinalSessionResult.vue';
import FeedbackSession from '@/components/session-result/FeedbackSession.vue';
import SessionGoToReportBlock from '@/components/session-result/SessionGoToReportBlock.vue';
import StreakInExerciseResult from '@/components/streak/StreakInExerciseResult.vue';

export default {
	name: 'SessionResult',
	components: {
		ScoreFinal,
		SessionPerformance,
		SessionBlocksPerformance,
		ButtonsFinalSessionResult,
		FeedbackSession,
		SessionGoToReportBlock,
		Loading,
		StreakInExerciseResult,
	},
	data() {
		return {
			loading: false,
			session: null,
			sessionScore: null,
			formationProgress: null,
		};
	},
	async mounted() {
		try {
			const { sessionId, formationId } = this.$route.params;

			this.loading = true;
			const session = await this.fetchSession(sessionId);
			this.session = session;

			// Ensure that formationProgress is fetched first to get the most up-to-date score when fetchSessionScore
			const formationProgress = await this.fetchFormationProgress(formationId);
			this.formationProgress = formationProgress;

			const sessionScore = await this.fetchSessionScore(sessionId, formationId);
			this.sessionScore = sessionScore;
			this.loading = false;

			if (process.env.VUE_APP_MIXPANEL == 'production') {
				this.$mixpanel.track('session-end');
			}
		} catch (error) {
			console.error('Error fetching:', error);
		}
	},
	beforeDestroy() {
		this.$confetti.stop();
	},
	methods: {
		async fetchFormationProgress(idFormation) {
			return await this.$store.dispatch('profile/formationProgress', { idFormation });
		},
		async fetchSession(sessionId) {
			return await this.$store.dispatch('sessions/getSessionDetail', { sessionId });
		},
		async fetchSessionScore(sessionId, formationId) {
			return await this.$store.dispatch('sessions/getSessionResponse', { sessionId, formationId });
		},
	},
};
</script>
